<template>
    <div>
        <ts-page-title
            :title="$t('shiftManagement.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('shiftManagement.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="$router.push({ name: 'shift-management-create' })"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('shiftManagement.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import expandRow from "./table-expand.vue";
export default {
    name: "shiftManagementIndex",
    data() {
        return {
            loading: false,
            width: "width: 300px",
            visible: false
        };
    },
    computed: {
        ...mapState("humanResource/shiftManagement", [
            "resources",
            "pagination"
        ]),
        search: {
            get() {
                return this.$store.state.humanResource.shiftManagement.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/shiftManagement/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/shiftManagement/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    type: "expand",
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        });
                    },
                    align: "center",
                    width: 50
                },
                {
                    title: this.$t("shiftManagement.shiftName"),
                    key: "shift_name"
                },
                {
                    title: this.$t("shiftManagement.shiftType"),
                    key: "shift_type",
                    align: "center",
                    filters: [
                        {
                            label: "Normal",
                            value: "Normal"
                        },
                        {
                            label: "Shift",
                            value: "Shift"
                        },
                        {
                            label: "Rotation",
                            value: "Rotation"
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                shift_type: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("shiftManagement.creasePeriod"),
                    key: "minute_allow",
                    align: "center"
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    align: "center",
                    width: 90
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/shiftManagement/fetch", attributes)
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "humanResource/shiftManagement/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "shift-management-edit",
                params: { id: record.shift_id }
            });
        },

        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch(
                    "humanResource/shiftManagement/destroy",
                    record.shift_id
                )
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "shiftManagement",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/shiftManagement/RESET_STATE");
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
